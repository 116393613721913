<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Talep Bilgileri</span>
        </template>
        <request-view />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Bilgileri</span>
        </template>
        <customer-info />
        <customer-invoice />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer-history :customer-id="dataItem.id_customers" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import CustomerInfo from '@/views/Admin/Customers/View/CustomerInfo.vue'
import CustomerInvoice from '@/views/Admin/Customers/View/CustomerInvoice.vue'
import CustomerHistory from '@/views/Admin/Customers/View/CustomerHistory.vue'
import RequestView from '@/views/Admin/Offers/Requests/RequestView.vue'

export default {
  name: 'RequestViewPage',
  components: {
    BTabs,
    BTab,
    CustomerInfo,
    CustomerInvoice,
    CustomerHistory,
    RequestView,
  },
  computed: {
    dataItem() {
      return this.$store.getters['baskets/dataItem']
    },
  },
  beforeCreate() {
    this.$store.commit('baskets/RESET_DATA_ITEM')
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('baskets/getDataView', this.$route.params.id)
        .then(response => {
          if (response.id_customers) {
            this.getCustomer(response.id_customers)
          }
        })
    },
    getCustomer(id) {
      this.$store.dispatch('customers/getDataItem', id)
    },
  },
}
</script>
