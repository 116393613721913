<template>
  <div>
    <b-card title="Teklif Talebi">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <div class="font-weight-bold text-primary">
            Firma Adı
          </div>
          <div>{{ dataItem.company }}</div>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <div class="font-weight-bold text-primary">
            Talep Eden
          </div>
          <div>{{ dataItem.customer_account }}</div>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <div class="font-weight-bold text-primary">
            Talep Edilen Temsilci
          </div>
          <div>{{ dataItem.username }}</div>
        </b-col>
        <b-col class="mt-2">
          <div class="font-weight-bold text-primary">
            Durum
          </div>
          <div>
            <span v-if="dataItem.sent">
              {{ moment(dataItem.sent).format('LLLL') }} tarihinde gönderildi.
              <div
                v-if="!dataItem.id_offers"
                class="text-info font-small-2"
              >
                Talep Beklemede
              </div>
              <div
                v-else
                class="text-success font-small-2"
              >
                Teklif Oluşturuldu
              </div>
            </span>
            <span
              v-else
              class="text-info"
            >
              Talep hazırlanıyor
            </span>
          </div>
        </b-col>
        <b-col
          v-if="dataItem.notes"
          cols="12"
          class="mt-2"
        >
          <div class="font-weight-bold text-primary">
            Müşteri Notu
          </div>
          <div>{{ dataItem.notes }}</div>
        </b-col>
      </b-row>
      <hr>
      <div class="mt-1">
        <b-button
          v-if="!dataItem.id_offers"
          variant="primary"
          :disabled="!dataItem.sent"
          :to="'/app/offers/request-add/' + dataItem.id"
        >
          <FeatherIcon icon="PlusCircleIcon" />
          Teklif Oluştur
        </b-button>
        <b-button
          v-else
          variant="success"
          :to="'/app/offers/view/' + dataItem.id_offers"
        >
          <FeatherIcon icon="EyeIcon" />
          Teklifi Görüntüle
        </b-button>
      </div>
    </b-card>
    <b-card title="Talep İçeriği">
      <b-row>
        <b-col
          v-for="item in dataItem.items"
          :key="item.id"
          cols="12"
          md="3"
          class="mb-3"
        >
          <div
            class="border rounded p-1 text-center"
            :class="item.is_batch? 'bg-light-warning' : ''"
          >
            <div class="overflow-hidden rounded">
              <div class="w-100">
                <b-img
                  v-if="item.image"
                  :src="baseURL + '/media/products/' + item.image"
                  fluid-grow
                />
                <empty-image v-else />
              </div>
              <div
                v-if="item.is_batch"
                class="mt-n5 position-absolute"
              >
                <b-button
                  variant="danger"
                  pill
                  class="btn-icon ml-1"
                  @click="setModal(item.items)"
                >
                  <FeatherIcon icon="InfoIcon" />
                </b-button>
              </div>
            </div>
            <div class="font-weight-bold text-primary mt-1">
              {{ item.title }}
            </div>
            <div class="text-muted">
              {{ item.pcode }}
            </div>
            <div class="text-danger font-weight-bold">
              <span
                v-if="item.is_batch"
              >
                {{ item.items.length }} x
              </span>
              {{ item.quantity }} {{ item.unit }}
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      v-model="modalData.status"
      title="Varyasyon Detay"
      size="lg"
      hide-footer
      centered
    >
      <b-table-simple
        bordered
        small
      >
        <b-thead>
          <b-tr>
            <b-th>Ürün Kodu</b-th>
            <b-th class="width-200 text-center">
              Miktar
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="item in modalData.items"
            :key="item.id"
          >
            <b-td>{{ item.title }}</b-td>
            <b-td class="text-center">
              {{ item.quantity }} {{ item.unit }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BImg, BButton, BModal, BTableSimple, BThead, BTr, BTh, BTd, BTbody,
} from 'bootstrap-vue'
import EmptyImage from '@/layouts/components/common/EmptyImage.vue'

export default {
  name: 'RequestView',
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BButton,
    BModal,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    EmptyImage,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
      modalData: {
        status: false,
        items: [],
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['baskets/dataItem']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('baskets/getDataView', this.$route.params.id)
    },
    setModal(items) {
      this.modalData.status = true
      this.modalData.items = items
    },
  },
}
</script>
